// cover styles
.cover{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  transform: translateX(50px);
  &__title-group{
    @include margin(left);
    margin-top: $spacing--large;
    .title{
      margin-bottom: $spacing--medium*2;
      .text{
        display: block;
        position: absolute;
        opacity: 0;
      }
    }
  }
  &__content{
    @include margin(left);
    @include breakpoint(small only){
      margin-bottom: $spacing--large;
    }
    &__action-CV{
      display: inline-flex;
      align-items: center;
      width: auto;
      position: relative;
      padding-bottom: $spacing--small;
      border-bottom: 2px solid $black;
      .text{
        @extend h6;
        font-weight: 900;
        margin-top: 0;
        margin-bottom: 0;
        margin-right: $spacing--small;
      }
      .icon{
        display: block;
        position: relative;
        width: 19px * 0.75;
        height: 26px * 0.75;
        margin: 0;
        svg{
          stroke: $black;
        }
      }
    }
  }
  &__email{
    position: absolute;
    bottom: 0;
    left: 0;
    @include margin(left);
    @include breakpoint(small only){
      position: relative;
      width: auto;
    };
  }
  &__social-list{
    position: absolute;
    bottom: 0;
    right: 0;
    @include margin(right);
    @include breakpoint(small only){
      position: relative;
      margin-left: $spacing--small;
      margin-bottom: $spacing--medium;
    };
    .social-list{
      margin-bottom: 1em;
    }
  }
}
.social-list{
  list-style: none;
  margin-left:0;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  @include breakpoint(small only){
    flex-direction: column;
  }
  .list-item{
    margin-right: $spacing--small;
    a{
      display: block;
      &:hover{
        border-bottom: none;
      }
      .icon{
        display: block;
        position: relative;
        width: 18px;
        height: 18px;
        margin-right:$spacing--medium;
        svg{
          display: block;
          width: 100%;
          height: 100%;
          fill:$black;
        }
      }
    }
  }
}
