// author styles
.author{
  background-color: $white;
  padding:$spacing--large 0;
  &__image{
    img{
      border-radius: 100%;
      max-width: 100px;
    }
  }
  &__text{
    .tagline{
      margin-bottom: 0;
    }
  }
}
