// case-item styles

.case-item{
  &__media{
    img{
      width: 100%;
      max-width: none;
    }
  }
}
