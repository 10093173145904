// profile styles
.profile{
  display: flex;
  position: relative;
  width: 100%;
  height: 100vh;
  opacity: 0;
  transform: translateX(50px);
  &__media{
    display: block;
    position: absolute;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    z-index: $layer--background;
    .underlay{
      @include underlay($background-color:rgba($black, 0.2));
    }
    img{
      height: 100%;
      max-width: none;
      width: auto;
      transform: translateX(0%);
      @include breakpoint(medium) {
        transform: translate3d(-25%, 0,0);
      }
      @include breakpoint(large) {
        transform: translate3d(-28%, 0,0);
      }
      @include breakpoint(xlarge) {
        transform: translate3d(-0%, 0,0);
      }
    }
  }
  &__content{
    align-self: flex-end;
    display: flex;
    position: relative;
    z-index: $layer--content;
    @include breakpoint(small only){
      flex-basis: 100%;
    }
    .current{
      color: white;
      @include margin(left);
      @include breakpoint(small only){
        width: 100%;
        text-align: right;
        margin-right: $spacing--small;
      }
      .title{
        margin-bottom: $spacing--small*0.5;
      }
      .company{
        margin-bottom: $spacing--small*0.5;
      }
    }
  }
}
