// hero styles
.hero{
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  &__backdrop{
    z-index: $layer--background - 1;
    position: absolute;
    top: $spacing--medium;
    right: 0;
    opacity: 0.2;
    width: 100vh;
    .negative-theme &{
      opacity: 0.3;
    }
    @include margin(right);
    .title{
      font-size: 800%;
      line-height: 0.8em;
      font-weight: 900;
      letter-spacing: 0;
      text-transform: uppercase;
      text-align: left;
      transform-origin: top right;
      transform: rotate(90deg) translateY(0%) translateX(100%);
      .word{
        overflow: hidden;
        display: block;
        .inner{
          display: block;
          // position: absolute;
        }
      }
    }
  }
  &__content{
    z-index: $layer--background;
    width: 80%;
    height: auto;
    position: relative;
    @include breakpoint(medium up){
      width: 60%;
    }
    &__img{
      img{
        max-width: none;
        width: 100%;
        box-shadow: 0 10px 50px rgba($black, 0.2);
      }
    }
    &__actions{
      position: absolute;
      right: 0;
      bottom: 0;
      transform: translateY(50%);
      .description--opened &{
        display: none;
      }
      .list{
        display: flex;
        position: relative;
        width: 100%;
        height: 100%;
        margin: 0;
        list-style: none;
        .list-item{
          display: flex;
          flex-direction: column;
          align-items: center;
          align-content: center;
          justify-content: center;
          position: relative;
          float: left;
          margin: 0 $spacing--medium;
          a{
            display: block;
            flex-direction: column;
            align-items: center;
            align-content: center;
            justify-content: center;
            width: 100%;
            height: 100%;
          }
          .icon{
            display: flex;
            flex-direction: column;
            align-items: center;
            align-content: center;
            justify-content: center;
            position: relative;
            background-color: $white;
            width: 40px;
            height: 40px;
            border-radius: 100%;
            box-shadow: 0 0 20px rgba($black, 0.35);
            transition: transform 0.5s $global-easeing;
            svg{
              width: 20px*0.7;
              height: 30px*0.7;
              stroke: $black;
            }
            &__rocket{
              svg{
                width: 23px*0.74;
                height: 35px*0.74;
                transform: rotate(45deg);
              }
            }
            &__info{
              svg{
                width: 6px*0.8;
                height: 17px*0.8;
              }
            }
            &:hover{
              cursor: pointer;
              transform: scale(1.1);
              transition: transform 0.5s $global-easeing;
            }
          }
          .hint{
            display: block;
            position: absolute;
            bottom: 0;
            z-index: $layer--content - 1;
            opacity: 0;
            color: $black;
            .negative-theme &{
              color: $white;
            }
          }
        }
      }
    }
  }
  &__description{
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    min-height: 100vh;
    height: 100%;
    top: 0;
    left: 0;
    padding: $spacing--medium*1.8125 0;
    z-index: $layer--content + 90;
    overflow: auto;
    .inner{
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      // background-color: $white;
      padding: $spacing--medium $spacing--large;
      width: 100%;
      color: $black;
      @include breakpoint(small only){
        padding: $spacing--medium $spacing--medium*1.75;
      }
      .negative-theme &{
        color: $white;
      }
      .content{
      }
      // box-shadow: 0 0 20px rgba($black, 0.35);
      .back{
        position: absolute;
        top: 0;
        @include breakpoint(small only){
          top: -spacing--medium;
          left: auto;
          right: $spacing--medium*2;
        }
        .icon{
          transform: rotate(270deg);
        }
        .negative-theme &{
          border-color: $white;
          svg{
            fill: $white;
          }
        }
      }
      @include breakpoint(medium up){
        width: 60%;
      }
    }
    .description{
      margin-top: $spacing--medium*1.5;
      width: 100%;
      @include breakpoint(small only){
        margin-top: $spacing--large;
      }
      .role{
        margin-bottom: $spacing--small*0.5;
      }
      p{
      }
    }
  }
}
