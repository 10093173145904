// navigation styles
.navigation{
  position: absolute;
  top: $spacing--medium;
  left: 0;
  opacity: 0;
  @include margin(left);
  @include breakpoint(small only){
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    margin-left: 0;
    opacity: 0;
    width: 100%;
    min-height: 100vh;
    background-color: $white;
  }
  .menu-list{
    display: none;
    list-style: none;
    transform-origin: top left;
    transform: rotate(90deg);
    &__index{
      .page-index &{
        display: none;
      }
    }
    @include breakpoint(medium up){
      display: block;
    }
    .list-item{
      float: left;
      margin-right: $spacing--medium;
      font-weight: 600;
      a{
        color: $white;
        border-bottom: 2px solid $white;
        padding-bottom: $spacing--small*0.25;
      }
      .page-project &{
        a{
          color: $black;
          border-color: $black;
        }
      }
      .page-blog &{
        a{
          color: $black;
          border-color: $black;
        }
      }
    }
    @include breakpoint(small only){
      transform: rotate(0);
      margin: 0;
      flex-direction: column;
      align-items: center;
      .list-item{
        display: flex;
        margin: 0 auto;
        padding: $spacing--medium 0;
        font-size: 200%;
        transform: translateY(50);
        opacity: 0;
        a{
          color: $black;
          border-color: $black;
        }
      }
    }
  }
}
