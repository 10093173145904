// blog styles
.blog{
  &__timestamp{
    position: absolute;
    top: 0;
    right: 0;
    color: $black;
    margin-top: $spacing--small;
  }
  &__title-group{
    // opacity: 0;
    transform: translateY(100);
    .title{
      font-size: 400%;
      font-weight: 900;
      margin-bottom: $spacing--medium;
    }
    .subtitle{
      color: rgba(0,0,0,0.5);
      font-weight: 400;
    }
  }
  &__body{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // opacity: 0;
    transform: translateY(100);
    font-size: rem-calc(20px);
    padding-bottom: $spacing--large;
    h1, h2, h3, h4, h5, h6{
      font-weight: 600;
      margin-bottom: $spacing--medium;
    }
    > *{
      &:last-child(){
        &:after{
          display: inline-block;
          position: relative;
          background-image: url(../images/icon__p-mark.svg);
          background-repeat: no-repeat;
          background-position: center;
          background-color: transparent;
          content: " ";
          width: 20px;
          height: 20px;
          margin-left: $spacing--small;
          transform: translateY(3px);
          // background-color: $black;
        }
      }
    }
    a{
      box-shadow: 0 5px 0 rgba(255,255,255,0.8);
      font-weight: 500;
      transition: box-shadow 0.3s ease-in-out;
      &:hover{
        box-shadow: 0 5px 0 rgba(0,0,0,0.8);
        transition: box-shadow 0.3s ease-in-out;
      }
    }
    pre{
      margin-bottom: $spacing--large;
      align-self: center;
      width: 100%;
      max-width: 100%;
      @include breakpoint(medium up){
        width: 120%;
      }
    }
    p{
      display: inline-block;
      float: left;
      max-width: none;
      position: relative;
      line-height: rem-calc(35px);
      margin-bottom: $spacing--medium;
    }
    blockquote{
    }
    img{
      margin: $spacing--small auto $spacing--medium;
    }
    video{
      width: 100%;
    }
    hr{
      display: inline-block;
      width: 100%;
      position: relative;
      border: none;
      margin: 0 auto $spacing--large;
      &:after{
        display: block;
        position: relative;
        margin-bottom: 0;
        text-align: left;
        content: "* * *"
      }
    }
    ul, ol{
      line-height: rem-calc($spacing--medium*1.5);
      margin-bottom: $spacing--medium;
      font-style: italic;
      font-weight: 400;
      li{
        margin-bottom: $spacing--small;
      }
    }
  }
  &__learnings{
    background-color: white;
    padding: $spacing--medium;
    margin-bottom: $spacing--large;
    outline: 5px solid rgba(255,255,255,0.5);
    ol{
      counter-reset: myCounter;
      list-style: none;
      li{
        padding-left: $spacing--medium*2;
        display: block;
        position: relative;
        margin-bottom: $spacing--medium;
        &:before{
          position: absolute;
          top: 0;
          left: 0;
          display: inline-block;
          width: $spacing--medium*1.5;
          height: $spacing--medium*1.5;
          content: counter(myCounter);
        	counter-increment: myCounter;
          background-color: #eeeeee;
          @extend .small;
          text-align: center;
          border-radius: 2px;
          font-style: normal;
          font-weight: 400;
          color: #666666;
        }
      }
    }
  }
}
