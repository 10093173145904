@mixin dash(
  $height:5px,
  $width:30px,
  $bg:#ffffff
)
{
  display: inline-block;
  position: relative;
  width: $width;
  height: $height;
  margin-bottom: $spacing--small;
  background-color: $bg;
}
@mixin margin($position: left) {
  @if $position == left{
    margin-left: $spacing--small;
    @include breakpoint(medium){
      margin-left: $spacing--medium;
    }
    @include breakpoint(xlarge){
      margin-left: $spacing--large;
    }
  }
  @else if $position == right{
    margin-right: $spacing--small;
    @include breakpoint(medium){
      margin-right: $spacing--medium;
    }
    @include breakpoint(xlarge){
      margin-right: $spacing--large;
    }
  }
  @else if $position == leftBottom{
    margin-left: $spacing--small;
    margin-bottom: $spacing--small;
    @include breakpoint(medium){
      margin-left: $spacing--medium;
      margin-bottom: $spacing--medium;
    }
    @include breakpoint(xlarge){
      margin-left: $spacing--large;
      margin-bottom: $spacing--large;
    }
  }
}

@mixin underlay($background-color:rgba($black, 0.8)){
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $background-color;
  z-index: $layer--background;
}
