// scroll-to-top styles
.scroll-to-top{
  position: fixed;
  right: 0;
  bottom: $spacing--medium;
  z-index: $layer--overlay;
  opacity: 0;
  transform: translate3d(0, 10px, 0);
  @include margin(right);
  &:hover{
    cursor: pointer;
  }
  .icon{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    position: relative;
    background-color: $white;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    box-shadow: 0 0 20px rgba($black, 0.35);
    transition: transform 0.5s $global-easeing;
    svg{
      width: 18px*0.7;
      height: 19px*0.7;
      stroke: $black;
      fill: none;
    }
  }
}
