.regular{
  font-weight: 400;
}
.bold{
  font-weight: 600;
}
.medium{
  font-weight: 500;
}
.black{
  font-weight: 900;
}
.meta{
  font-size: 80%;
  text-transform: uppercase;
}
.small{
  font-size: 80%;
}
.lhTight{
  line-height: 0.85em;
}
.heavey{
  font-weight: 900;
}
.highlight{
}
.link{
  &:hover{
  }
}
h1, h2, h3, h4, h5, h6{
  margin-top: $spacing--medium;
}
.title{
  &--section{
    @extend .meta;
    font-weight: 900;
    text-transform: uppercase;
  }
}
.label{
  // border-top: 1px solid $black;
  // padding-top: $spacing--small;
  text-transform: uppercase;
  display: block;
  @include breakpoint(medium up){
    display: none;
  }
}
a{
  color: $black;
}
p{
  max-width: 36em;
  // display: inline-block;
}
blockquote{
  display: flex;
  color: $black;
  width: calc(100% + 64px);
  border: none;
  padding:$spacing--medium $spacing--medium $spacing--small;
  margin: $spacing--medium 0 $spacing--large;
  background-color: $white;
  transform: translateX(-32px);
  p{
    @extend h4;
    font-weight: 300 !important;
    text-align: center;
    position: relative;
    color: $black;
    margin: auto;
    @include breakpoint(medium up){
      width: 60%;
    }
  }
  cite{
    font-weight: 600;
    font-style: normal;
    color: $black;
    &:before{
      content: " ";
      text-align: center;
      margin: $spacing--medium auto $spacing--small;
      display: block;
      width: 1px;
      height: 20px;
      background-color: $black;
    }
  }
}
