// loading styles
.loading{
  // display: none;
  position: absolute;
  top: $spacing--medium*1.5;
  right: 0;
  z-index: $layer--navigation + 199;
  @include margin(right);
  &__bar{
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 15px;
    height: 80px;
    background-color: $light-gray;
    opacity: 0;
    transform-origin: top right;
    transform: rotate(90deg) translateY(0%) translateX(100%);
  }
  &__percentage{
    @extend h1;
    display: block;
    position: absolute;
    top: $spacing--medium*2;
    right: 0;
    font-size: 600%;
    line-height: 0.8em;
    font-weight: 900;
    text-transform: uppercase;
    color: $light-gray;
    letter-spacing: 0.1em;
    transform-origin: top right;
    transform: rotate(90deg) translateY(0%) translateX(100%);
  }
}
