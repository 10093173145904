.site__navigation{
  display: block;
  width: 100%;
  position: fixed;
  z-index: $layer--navigation;
}
.site__body{
  display: block;
  position: relative;
  width: 100%;
  float: left;
  background-color: $white;
}
.site__footer{
}
.menu--opened{
  overflow: hidden;
  z-index: $layer--content;
}
.row{
  &--fullscreen{
    max-width: none;
    width: 100%;
    height: 100vh;
  }
  &--no-gutter{
    padding:0;
  }
  &--no-gutter-left{
    padding-left:0;
    @include breakpoint(small only){
      padding: 0;
    }
  }
}
code{
  border-radius: 2px;
  margin-bottom: $spacing--small;
}
.container{
  display: block;
  background-color: $white;
  &--fullscreen{
    height: 100vh;
    .column{
      position: relative;
    }
  }
  &--double-padding-top{
    padding-top: $spacing--large;
  }
  &--double-padding{
    padding: $spacing--large*2 0;
  }
  &--bg-01{
    background-color: $background-color-01;
  }
  &--bg-02{
    background-color: $background-color-02;
  }
  &--primary{
  }
  &--top{
    transform-origin: top center;
  }
  .page-blog &{
    opacity: 0;
    transform: translateY(100px);
  }
}
.padding-top{
  padding-top:$spacing--medium*1.5;
}

.icon{
  display: block;
  position: relative;
  margin: auto;
  width: 100%;
  height: 100%;
  svg{
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
  }
}

.overlay--opened{
  position: fixed;
  overflow: hidden;
  height: 100%;
}
