// about styles
.about{
  display: none;
  position: fixed;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  top: 0;
  left: 0;
  padding: $spacing--medium*1.8125 0;
  background-color: $white;
  z-index: $layer--navigation + 99;
  overflow: auto;
  .inner{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    @include breakpoint(small only){
      margin-top: $spacing--large*1.5;
      margin-bottom: $spacing--large*1.5;
    }
  }

  .list{
    margin:0 0 $spacing--medium*1.5 0;
    list-style: none;
    position: relative;
    .list-item{
      margin-bottom: $spacing--small;
      .title{
        margin-bottom: 0;
      }
      .list{
        .list-item{
          margin-left: $spacing--small;
          position: relative;
          &:before{
            content: " ";
            display: inline-block;
            position: absolute;
            width: 5px;
            height: 1px;
            left: -$spacing--small;
            margin-top: $spacing--small;
            background-color: $black;
          }
        }
      }
    }
  }
  .section{
    margin-bottom: $spacing--large;
    &.clients{
      .list{
        .list-item{
          margin-left: $spacing--small;
          position: relative;
          &:before{
            content: " ";
            display: inline-block;
            position: absolute;
            width: 5px;
            height: 1px;
            left: -$spacing--small;
            margin-top: $spacing--small;
            background-color: $black;
          }
        }
      }
    }
  }
}
