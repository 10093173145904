// Normalize Styles
@import 'normalize';

@import 'settings';
@import 'foundation';
@include foundation-everything(true);
@include foundation-flex-grid;

// Import Modules
@import 'prerequisites/prerequisites',
        'navigation/navigation',
        'mobile-navigation/mobile-navigation',
        'dna/dna',
        'cover/cover',
        'back/back',
        'loading/loading',
        'hero/hero',
        'case-item/case-item',
        'profile/profile',
        'about/about',
        'scroll-to-top/scroll-to-top',
        'project-list/project-list',
        'blog-list/blog-list',
        'author/author',
        'blog/blog';

// Box sizing partial styles:
// Apply a natural box layout model to all elements
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.yeogurt-info {
  text-align: center;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.version {
  font-size: 14px;
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.logo {
  margin-top: 15px;
}

// Styling on warning for users on IE7 or below
.browsehappy {
  text-align: center;
  margin: 0;
  background: #fff8e2;
  color: #000;
  padding: 0.5em 0;
}
