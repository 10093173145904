// back styles
.back{
  display: none;
  // display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 50px;
  height: 50px;
  position: fixed;
  top: $spacing--medium;
  left: 0;
  @include margin(left);
  z-index: $layer--navigation + 199;
  border-bottom: 2px solid $black;
  &:hover{
    cursor: pointer;
  }
  .icon{
    transform: rotate(180deg) translate3d(1px,-2px,0);
    width: 24px;
    height: 14px;
    svg{
    }
  }
  [data-overlay='blogList'] &{
    border-bottom-color: $white;
    .icon{
      svg{
        fill: $white;
      }
    }
  }
}
