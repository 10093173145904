// mobile-navigation styles
.mobile-navigation{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: $black;
  @include breakpoint(medium up){
    display: none;
  }
  .toggle--mobile-navigaiton{
    display: block;
    position: relative;
    border: none;
    padding:$spacing--small 0;
    width: 100%;
    height: 60px;
    margin: auto;
    overflow: hidden;
    .button__text{
      display: inline-block;
      text-align: center;
      position: absolute;
      top: 18px;
      left: 50%;
      color:$white;
      border-bottom: 2px solid $white;
      padding:0 0 $spacing--small*0.5;
      transform: translateX(-50%);
      overflow: hidden;
      &--menu{
      }
      &--back{
        display: inline-block;
        opacity: 0;
      }
    }
    &:focus{
      outline: none;
    }
  }
}
