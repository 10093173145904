// blog-list styles
.blog-list{
  display: none;
  position: fixed;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  top: 0;
  left: 0;
  padding: $spacing--medium*1.8125 0;
  background-color: $black;
  z-index: $layer--navigation + 99;
  overflow: auto;
  .inner{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    @include breakpoint(small only){
      margin-top: $spacing--large*1.5;
      margin-bottom: $spacing--large*1.5;
      margin-left: $spacing--small;
    }
    .list{
      list-style: none;
      margin:0;
      width: 100%;
      @include breakpoint(medium){
        width: 80%;
      }
      @include breakpoint(medium){
        width: 50%;
      }
      .list-item{
        margin-bottom: $spacing--large;
        a{
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          justify-content: flex-start;
          color: $white;
          .title{}
        }
      }
    }
  }
}
.timestamp{
  display: block;
  width: $spacing--large*1.5;
  color: fade-out($white, 0.5);
  .date{
    @extend h1;
    line-height: 0.85em;
    display: block;
    font-weight: 900;
    letter-spacing: 0.2rem;
    width: $spacing--large*1.5;
  }
  .month{
  }
  .year{
  }
}
