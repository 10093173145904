// project-list styles
.project-list{
  display: none;
  position: fixed;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  top: 0;
  left: 0;
  padding: $spacing--medium*1.8125 0;
  background-color: $white;
  z-index: $layer--navigation + 99;
  overflow: auto;
  .inner{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    @include breakpoint(small only){
      margin-top: $spacing--large*1.5;
      margin-bottom: $spacing--large*1.5;
      margin-left: $spacing--small;
    }
  }
  &__media{
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 33%;
    height: auto;
    @include margin(right);
    z-index: $layer--background;
  }
  .loading{
    z-index: $layer--content;
  }
  .list{
    display: block;
    list-style: none;
    margin:0;
    z-index: $layer--content + 1;
    a{
      display: block;
      transition: opacity 0.4s ease-in-out;
    }
    .list-item{
      margin-bottom: $spacing--medium;
      .title{
        margin-bottom: 0;
      }
      .role{
        color: $dark-gray;
        display: block;
      }
      .usp{
        color: $dark-gray;
        display: block;
      }
      &.mute{
        a{
          opacity: 0.4;
          transition: opacity 0.4s ease-in-out 0.2s;
        }
      }
      &--unpublished{
        &:hover{
          cursor: not-allowed;
        }
        h3{
          text-decoration: line-through;
        }
        .title{
          color: lighten($dark-gray, 30%);
        }
        .role{
          color: lighten($dark-gray, 30%);
        }
      }
    }
  }
}
